import React from 'react'
import { useParams } from 'react-router'
import { useHistory, generatePath } from 'react-router'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
} from '@labsavvyapp/ui-components'
import {
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import style from './PackagesList.module.css'
import PackagesListRow from './PackagesListRow'
import { PARTNERS } from '../../../../../config/routes'

export default function PackagesList({
  data,
  fetchMore,
  loading,
  onDeleteClick,
  provider,
}) {
  const { push } = useHistory()
  const { partnerId, projectId } = useParams()

  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.listPartnerProjectPackages.packages
    pagination.page = data.listPartnerProjectPackages.page
    pagination.pages = data.listPartnerProjectPackages.pages
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Package Name',
            className: style.column,
          },
          {
            name: 'LabSavvy Price',
            className: style.column,
          },
          {
            name: 'Partner Price',
            className: style.column,
          },
          {
            name: 'Retail Price',
            className: style.column,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="packages-list"
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('listPartnerProjectPackages', 'packages', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(items) && provider && (
          <p style={{ fontWeight: 400, marginTop: 30, marginLeft: 20 }}>
            <b>No packages were found.</b> You may add a package using the Add
            Package button above. <b>If you feel this is an error,</b> you may
            try to <RefreshButton />
            &mdash; this may be a momentary issue.
          </p>
        )}

        {!isEmpty(items) && (
          <PackagesListRow items={items} onDeleteClick={onDeleteClick} />
        )}

        {!provider && (
          <p style={{ fontWeight: 400, marginTop: 30, marginLeft: 20 }}>
            <b>No labs associated to this project.</b> You must add a lab first{' '}
            <a
              href="/add-lab"
              style={{
                color: '#C93BC9',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault()
                push(
                  generatePath(PARTNERS.projects.admin.section, {
                    partnerId: partnerId,
                    projectId: projectId,
                    section: 'labs',
                  }),
                )
              }}
            >
              here
            </a>{' '}
            before adding a package.
          </p>
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
