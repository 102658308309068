import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useQuery } from 'react-apollo'
import { ListProviders } from '../../graphql/providers/queries'
import { StyledProviderDropdown, StyledKitSearch } from './KitPage.styles'
import { Button, ToolbarHeader, useLocalStorage } from '@labsavvyapp/components'

import { KitsList } from './KitsListPage/KitsList'
import { GET_KIT_LIST } from '../../graphql/kits/queries'
import { KIT } from '../../config/routes'
import { useHistory } from 'react-router'
import { LIST_CONFIGS } from '../../graphql/settings/queries'
import { FULFILLMENTS_FILTER } from './constants'

export const KitPage = () => {
  const { push, location } = useHistory()

  const [providers, setProviders] = useState()
  const [userSelectedProvider, setUserSelectedProvider] = useLocalStorage({
    key: 'selectedProviderKitsPage',
  })

  const [kitsSearchKey, setKitsSearchKey] = useState('')
  const [fulfillmentsData, setFulfillmentsData] = useState([])

  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  const { data: fullfillmentsList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: FULFILLMENTS_FILTER,
    },
  })

  useEffect(() => {
    if (location?.state?.refetch) {
      refetchKitList()
    }
  }, [location])

  useEffect(() => {
    if (providerList?.listProviders && !providers) {
      setProviders(providerList.listProviders.providers)
    }
  }, [providerList, providers])

  useEffect(() => {
    const fullfillmentsListSelectData = _.map(
      fullfillmentsList?.listConfigs?.configs,
      (config) => ({
        value: config._id,
        label: config.name,
      }),
    )
    setFulfillmentsData(fullfillmentsListSelectData)
  }, [fullfillmentsList])

  /** Maintain last selected laboratory */
  useEffect(() => {
    if (!userSelectedProvider) {
      if (providers?.length > 0) {
        setUserSelectedProvider(providers[0])
      }
    }
  }, [userSelectedProvider, providers])

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, { _id: value })
    setUserSelectedProvider(provider)
  }

  const {
    data: getKitListResponse,
    fetchMore,
    loading,
    networkStatus,
    refetch: refetchKitList,
  } = useQuery(GET_KIT_LIST, {
    notifyOnNetworkStatusChange: true,
  })

  return (
    <MainContainer>
      <Helmet>
        <title>Kits</title>
      </Helmet>

      <ToolbarHeader title="Kits">
        <span>Laboratory:</span>
        {providers && (
          <StyledProviderDropdown
            selection
            value={userSelectedProvider?._id}
            options={providers.map((item) => ({
              text: item.name,
              value: item._id,
              key: item._id,
            }))}
            onChange={handleSetProvider}
          />
        )}
        <StyledKitSearch
          placeholder="Search a Kit"
          showNoResults={false}
          searchText={kitsSearchKey}
          onSearchChange={setKitsSearchKey}
        />
        <Button
          radius="xl"
          size="md"
          uppercase
          variant="filled"
          onClick={() =>
            push({
              pathname: KIT.new,
              state: { laboratory: userSelectedProvider },
            })
          }
        >
          New Kit
        </Button>
      </ToolbarHeader>
      <KitsList
        data={getKitListResponse?.listKits}
        loading={networkStatus === 1 || networkStatus === 3 || loading}
        fetchMore={fetchMore}
        searchKey={kitsSearchKey}
        userSelectedProvider={userSelectedProvider}
        providerList={providers}
        fulfillmentList={fulfillmentsData}
      />
    </MainContainer>
  )
}
