import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import {
  GetPartnerWebhookEventSettings,
  ListHookEvents,
} from '../../../../../../graphql/partner/queries'
import MainContainer from '../../../../../../components/MainContainer/MainContainer'
import {
  Grid,
  Select,
  Button,
  TextInput,
  LoadingModal,
  useForm,
  useDisclosure,
} from '@labsavvyapp/components'
import styled from '@emotion/styled'
import { UpdatePartner } from '../../../../../../graphql/partner/mutations'
import { useHistory, useParams } from 'react-router'

const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`
export default function AddWebhookEvent() {
  const { partnerId } = useParams()
  const { push } = useHistory()

  const [
    isSavingModalOpen,
    { open: openSavingModal, close: closeSavingModal },
  ] = useDisclosure(false)

  const form = useForm({
    initialValues: {
      event_code: '',
      url: '',
      extra_payload: '',
      auth: {
        type: '',
        username: '',
        password: '',
        token: '',
      },
      headers1: { code: '', value: '' },
      headers2: { code: '', value: '' },
      headers3: { code: '', value: '' },
      headers4: { code: '', value: '' },
      headers5: { code: '', value: '' },
    },
    validate: {
      event_code: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
      url: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
      extra_payload: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
    },
  })

  const { data: events, loading: eventsLoading } = useQuery(ListHookEvents)
  const { data: webhookEvents } = useQuery(GetPartnerWebhookEventSettings, {
    variables: { id: partnerId },
  })
  const [
    updatePartner,
    { loading: updatePartnerLoading, error: updatePartnerError },
  ] = useMutation(UpdatePartner, {
    refetchQueries: [
      {
        query: GetPartnerWebhookEventSettings,
        variables: {
          id: partnerId,
        },
      },
    ],
  })

  const [eventsData, setEventsData] = useState([])

  useEffect(() => {
    if (!eventsLoading) {
      const eventsListSelectData = _.map(events?.listHookEvents, (event) => ({
        value: event,
        label: event,
      }))
      setEventsData(eventsListSelectData)
    }
  }, [eventsLoading])

  const handleOnSaveWebhookEventClick = async (values) => {
    await updatePartner({
      variables: {
        id: partnerId,
        data: {
          hooks: {
            webhook: {
              subscribers: [
                ...webhookEvents?.getPartner?.hooks?.webhook?.subscribers,
                {
                  event_code: values.event_code,
                  url: values.url,
                  extra_payload: values.extra_payload,
                  auth: values.auth,
                  headers: [
                    values.headers1,
                    values.headers2,
                    values.headers3,
                    values.headers4,
                    values.headers5,
                  ],
                },
              ],
            },
          },
        },
      },
    })
    openSavingModal()
    setTimeout(() => {
      push('webhook')
    }, 3000)
  }

  return (
    <MainContainer>
      <form
        onSubmit={form.onSubmit((values) =>
          handleOnSaveWebhookEventClick(values),
        )}
      >
        <Grid>
          <Grid.Col md={6} lg={6}>
            <Select
              data={eventsData}
              label="Event"
              placeholder="Select an Event"
              searchable
              disabled={eventsData?.length === 0}
              {...form.getInputProps('event_code')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={6}>
            <TextInput
              label="URL Endpoint"
              placeholder="URL Endpoint"
              withAsterisk
              {...form.getInputProps('url')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={6}>
            <TextInput
              label="Extra Payload"
              placeholder="Extra Payload"
              withAsterisk
              {...form.getInputProps('extra_payload')}
            />
          </Grid.Col>
        </Grid>
        <Container>
          <b>Authentication</b>
        </Container>
        <Grid>
          <Grid.Col lg={6}>
            <Select
              data={['basic', 'none', 'token']}
              label="Type"
              placeholder="Select a Type"
              searchable
              {...form.getInputProps('auth.type')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              label="Username"
              placeholder="Username"
              withAsterisk
              {...form.getInputProps('auth.username')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              label="Password"
              placeholder="Password"
              withAsterisk
              {...form.getInputProps('auth.password')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              label="Token"
              placeholder="Token"
              withAsterisk
              {...form.getInputProps('auth.token')}
            />
          </Grid.Col>
        </Grid>
        <Container>
          <b>Headers</b>
        </Container>
        <Grid>
          <Grid.Col lg={6}>
            <TextInput
              label="Code"
              placeholder="Code"
              {...form.getInputProps('headers1.code')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              label="Value"
              placeholder="Value"
              {...form.getInputProps('headers1.value')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Code"
              {...form.getInputProps('headers2.code')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Value"
              {...form.getInputProps('headers2.value')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Code"
              {...form.getInputProps('headers3.code')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Value"
              {...form.getInputProps('headers3.value')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Code"
              {...form.getInputProps('headers4.code')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Value"
              {...form.getInputProps('headers4.value')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Code"
              {...form.getInputProps('headers5.code')}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextInput
              placeholder="Value"
              {...form.getInputProps('headers5.value')}
            />
          </Grid.Col>
        </Grid>
        <Container>
          <Button variant="filled" type="submit" size="lg" uppercase>
            Save Changes
          </Button>
        </Container>
      </form>

      <LoadingModal
        loadingMessage="We are saving your changes... please wait"
        successMessage="Your data has been saved"
        errorMessage="Something went wrong. Please try again later."
        isLoading={updatePartnerLoading}
        opened={isSavingModalOpen}
        onClose={closeSavingModal}
        hasError={updatePartnerError}
      />
    </MainContainer>
  )
}
