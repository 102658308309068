import React from 'react'

import { ListRow } from '@labsavvyapp/ui-components'
import classnames from 'classnames'

import style from './PackagesList.module.css'
import deleteIcon from '../../../../../components/Icons/delete-icon.svg'

export default function PackagesListRow({ items, onDeleteClick }) {
  return items.map((item) => (
    <ListRow key={item.package.id} className={style.row}>
      <div className={style.column}>{item.name}</div>
      <div className={style.column}>{`$ ${item.package.lsPrice}`}</div>
      <div className={style.column}>{`$ ${item.package.partnerPrice}`}</div>
      <div className={style.column}>{`$ ${item.package.retailPrice}`}</div>
      <div
        className={classnames(style.column, style.deleteIcon)}
        onClick={() => onDeleteClick(item.package.id)}
      >
        <img alt="delete-package" src={deleteIcon} />
      </div>
    </ListRow>
  ))
}
