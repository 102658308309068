import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import {
  useHistory,
  useParams,
  useLocation,
  matchPath,
  generatePath,
} from 'react-router'
import { ToolbarHeader } from '@labsavvyapp/components'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { utc } from 'moment'
import classnames from 'classnames'
import { get } from 'lodash'
import sharedStyle from '../../../../styles/shared.module.css'
import style from './PartnerHeader.module.css'
import { PARTNERS, ERROR } from '../../../../config/routes'
import { GetPartner } from '../../../../graphql/partner/queries.js'

function formatPhoneNumber(phone) {
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
    6,
  )}`
}

export default function PartnerDetailsHeader() {
  const { partnerId } = useParams()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const isPartnerAdminPath = matchPath(pathname, PARTNERS.admin.base, {
    isExact: true,
  })

  // Partner data
  const { data, loading } = useQuery(GetPartner, {
    variables: {
      id: partnerId,
    },
    skip: !partnerId,
  })
  const partnerData = camelizeKeys(data)
  if (partnerData && !partnerData.getPartner) {
    push(ERROR.notFound)
  }

  const name = get(partnerData, 'getPartner.name', null)
  const firstName = get(partnerData, 'getPartner.contact.name.first')
  const lastName = get(partnerData, 'getPartner.contact.name.last')
  const phoneNumber = get(partnerData, 'getPartner.contact.phone1.number', null)
  const email = get(partnerData, 'getPartner.contact.email', null)
  const status = get(partnerData, 'getPartner.status', null)
  const logo = get(partnerData, 'getPartner.logo')

  // Back button
  const backButtonURL = isPartnerAdminPath
    ? generatePath(PARTNERS.details, { partnerId })
    : PARTNERS.list

  return (
    <>
      <Helmet>{partnerId && <title>{`Partner - ${name}`}</title>}</Helmet>
      <ToolbarHeader
        title={partnerId ? `${name}` : 'New Partner'}
        backButtonAction={() => push(backButtonURL)}
        backButtonText={'Back to Partner List'}
        badgeTitle={isPartnerAdminPath ? 'Partner Admin' : 'Partner'}
      >
        {/* Details */}
        {partnerId && !loading && partnerData.getPartner && (
          <>
            <div
              className={classnames(style.dataContainer, {
                [sharedStyle.marginRight30]: logo,
                [sharedStyle.noMarginRight]: !logo,
              })}
              data-test="partner-header"
            >
              {partnerData.getPartner.contact && (
                <div className={style.dataRow}>
                  {firstName && lastName && (
                    <>
                      <div className={style.dataLabel}>Contact:</div>
                      <div
                        className={classnames({
                          [sharedStyle.marginRight20]:
                            partnerData.getPartner.contact.phone1.number,
                        })}
                      >
                        {`${firstName} ${lastName}`}
                      </div>
                    </>
                  )}

                  <>
                    <div
                      className={classnames({
                        [sharedStyle.marginRight20]: email,
                      })}
                    >
                      {phoneNumber && formatPhoneNumber(phoneNumber)}
                    </div>
                    <div>{email}</div>
                  </>
                </div>
              )}

              <div className={style.dataRow}>
                <div className={style.dataLabel}>Date Created:</div>
                <div
                  className={classnames({
                    [sharedStyle.marginRight20]: status,
                  })}
                >
                  {utc(partnerData.getPartner.createdAt).format('M/D/YY')}
                </div>
                {status && (
                  <>
                    <div className={style.dataLabel}>|</div>
                    <div className={style.dataLabel}>Status:</div>
                    <div className={style.status}>{status}</div>
                  </>
                )}
              </div>

              {!isPartnerAdminPath && (
                <Link
                  to={`${PARTNERS.admin.base.replace(
                    ':partnerId',
                    partnerId,
                  )}/general`}
                  className={style.link}
                >
                  <Icon link name="settings" /> Partner Admin
                </Link>
              )}
            </div>

            {/* Logo */}
            {logo && (
              <div className={style.logoContainer}>
                <div className={style.logoFrame}>
                  <img className={style.logo} src={logo.url} alt="logo" />
                </div>
              </div>
            )}
          </>
        )}
      </ToolbarHeader>
    </>
  )
}
