import React from 'react'

import { useHistory, useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { utc } from 'moment'
import { get } from 'lodash'
import classnames from 'classnames'
import { ToolbarHeader } from '@labsavvyapp/components'
import sharedStyle from '../../../../styles/shared.module.css'
import style from './ProjectHeader.module.css'
import { PARTNERS } from '../../../../config/routes'
import {
  GetPartner,
  GetPartnerProject,
  GetPartnerProjectBranding,
} from '../../../../graphql/partner/queries.js'

function formatPhoneNumber(phone) {
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
    6,
  )}`
}

export default function ProjectHeader() {
  const { partnerId, projectId } = useParams()
  const { push } = useHistory()

  // Partner data
  const { data: partnerResponse, loading: partnerLoading } = useQuery(
    GetPartner,
    {
      variables: {
        id: partnerId,
      },
    },
  )
  const partnerData = camelizeKeys(partnerResponse)
  const partnerName = get(partnerData, 'getPartner.name', null)
  const partnerContact = get(partnerData, 'getPartner.contact')
  const partnerContactName = `${get(partnerContact, 'name.first')} ${get(
    partnerContact,
    'name.last',
  )}`

  // Project data
  const { data: partnerProjectResponse, loading: partnerProjectLoading } =
    useQuery(GetPartnerProject, {
      variables: {
        partnerId: partnerId,
        projectId: projectId,
      },
      skip: !projectId,
    })
  const partnerProjectData = camelizeKeys(partnerProjectResponse)
  const projectName = get(partnerProjectData, 'getPartnerProject.name', null)

  const { data } = useQuery(GetPartnerProjectBranding, {
    variables: { partnerId, projectId },
    skip: !projectId,
  })

  const projectLogo = data?.getPartnerProject?.logo

  // Back button
  const backButtonText = !partnerLoading && `Back to ${partnerName}`
  const backButtonURL = PARTNERS.details.replace(':partnerId', partnerId)

  return (
    <ToolbarHeader
      title={projectName ? projectName : 'New Project'}
      backButtonAction={() => push(backButtonURL)}
      backButtonText={backButtonText}
      badgeTitle="Project"
    >
      {/* Details */}
      {partnerId && !partnerLoading && !partnerProjectLoading && (
        <>
          <div
            className={style.dataContainer}
            data-test="project-header-information"
          >
            <div className={style.dataRow}>
              <div className={style.dataLabel}>Organization:</div>
              <div className={sharedStyle.marginRight20}>{partnerName}</div>
              {partnerContact && (
                <>
                  <div className={style.dataLabel}>Contact:</div>
                  {partnerContactName && (
                    <div className={sharedStyle.marginRight20}>
                      {partnerContactName}
                    </div>
                  )}

                  {partnerContact.phone1.number && (
                    <div
                      className={classnames({
                        [sharedStyle.marginRight20]: partnerContact.email,
                      })}
                    >
                      {formatPhoneNumber(partnerContact.phone1.number)}
                    </div>
                  )}
                  <div>{partnerContact.email}</div>
                </>
              )}
            </div>

            {partnerProjectData && (
              <div className={style.dataRow}>
                <div className={style.dataLabel}>Date Created:</div>
                <div className={sharedStyle.marginRight20}>
                  {utc(partnerProjectData.getPartnerProject.createdAt).format(
                    'M/D/YY',
                  )}
                </div>
                <div className={style.dataLabel}>|</div>
                <div className={style.dataLabel}>Status:</div>
                <div className={style.status}>
                  {partnerProjectData.getPartnerProject.status}
                </div>
              </div>
            )}
          </div>

          {/* Logo */}
          {projectLogo && (
            <div className={style.logoContainer}>
              <div className={style.logoFrame}>
                <img className={style.logo} src={projectLogo.url} alt="logo" />
              </div>
            </div>
          )}
        </>
      )}
    </ToolbarHeader>
  )
}
