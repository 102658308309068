import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import {
  GetPartnerInternalEventSettings,
  ListHookActions,
  ListHookEvents,
} from '../../../../../../graphql/partner/queries'
import MainContainer from '../../../../../../components/MainContainer/MainContainer'
import {
  Grid,
  Select,
  Button,
  LoadingModal,
  useForm,
  useDisclosure,
} from '@labsavvyapp/components'
import styled from '@emotion/styled'
import { UpdatePartner } from '../../../../../../graphql/partner/mutations'
import { useHistory, useParams } from 'react-router'

const ButtonContainer = styled.div`
  margin-top: 20px;
`
export default function AddInternalEvent() {
  const { partnerId } = useParams()
  const { push } = useHistory()

  const [
    isSavingModalOpen,
    { open: openSavingModal, close: closeSavingModal },
  ] = useDisclosure(false)

  const form = useForm({
    initialValues: { event_code: '', sub: '' },
    validate: {
      event_code: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
      sub: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
    },
  })

  const { data: events, loading: eventsLoading } = useQuery(ListHookEvents)
  const { data: actions, loading: actionsLoading } = useQuery(ListHookActions)
  const { data: internalEvents } = useQuery(GetPartnerInternalEventSettings, {
    variables: { id: partnerId },
  })
  const [
    updatePartner,
    { loading: updatePartnerLoading, error: updatePartnerError },
  ] = useMutation(UpdatePartner, {
    refetchQueries: [
      {
        query: GetPartnerInternalEventSettings,
        variables: {
          id: partnerId,
        },
      },
    ],
  })

  const [eventsData, setEventsData] = useState([])
  const [actionsData, setActionsData] = useState([])

  useEffect(() => {
    if (!eventsLoading) {
      const eventsListSelectData = _.map(events?.listHookEvents, (event) => ({
        value: event,
        label: event,
      }))
      setEventsData(eventsListSelectData)
    }

    if (!actionsLoading) {
      const actionListSelectData = _.map(
        actions?.listHookActions,
        (action) => ({
          value: action,
          label: action,
        }),
      )
      setActionsData(actionListSelectData)
    }
  }, [eventsLoading, actionsLoading])

  const handleOnSaveInternalEventClick = async (values) => {
    await updatePartner({
      variables: {
        id: partnerId,
        data: {
          hooks: {
            internal: {
              subscribers: [
                ...internalEvents?.getPartner?.hooks?.internal?.subscribers,
                { event_code: values.event_code, sub: values.sub },
              ],
            },
          },
        },
      },
    })
    openSavingModal()
    setTimeout(() => {
      push('internal')
    }, 3000)
  }

  return (
    <MainContainer>
      <form
        onSubmit={form.onSubmit((values) =>
          handleOnSaveInternalEventClick(values),
        )}
      >
        <Grid>
          <Grid.Col md={6} lg={6}>
            <Select
              data={eventsData}
              label="Event"
              placeholder="Select an Event"
              searchable
              disabled={eventsData?.length === 0}
              {...form.getInputProps('event_code')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={6}>
            <Select
              data={actionsData}
              label="Action"
              placeholder="Select an Action"
              searchable
              withAsterisk
              disabled={actionsData?.length === 0}
              {...form.getInputProps('sub')}
            />
          </Grid.Col>
          <ButtonContainer>
            <Button variant="filled" type="submit" size="lg" uppercase>
              Save Changes
            </Button>
          </ButtonContainer>
        </Grid>
      </form>

      <LoadingModal
        loadingMessage="We are saving your changes... please wait"
        successMessage="Your data has been saved"
        errorMessage="Something went wrong. Please try again later."
        isLoading={updatePartnerLoading}
        opened={isSavingModalOpen}
        onClose={closeSavingModal}
        hasError={updatePartnerError}
      />
    </MainContainer>
  )
}
