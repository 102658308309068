import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import {
  Button,
  ToolbarHeader,
  TextInput,
  Select,
  Grid,
  TransferList,
  LoadingModal,
  useForm,
  useDisclosure,
} from '@labsavvyapp/components'

import { useMutation, useQuery } from 'react-apollo'
import { useHistory } from 'react-router'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { Helmet } from 'react-helmet-async'
import { CREATE_KIT } from '../../../graphql/kits/mutations'
import { KIT } from '../../../config/routes'
import { ListProviders } from '../../../graphql/providers/queries'
import { LIST_CONFIGS } from '../../../graphql/settings/queries'
import {
  BRANDS_FILTER,
  CATEGORIES_FILTER,
  FULFILLMENTS_FILTER,
  KIT_FORM_INITIAL_VALUES,
  KIT_FORM_VALIDATIONS,
} from '../constants'
import {
  ButtonGroup,
  EmptyContainer,
  ErrorMessage,
  FormSection,
  PanelSection,
  ScrollableContainer,
  StickyFooter,
} from '../KitPage.styles'
import { GET_COMPENDIUM_PANELS } from '../../../graphql/compendia/queries'

export const NewKitPage = () => {
  const { push, location } = useHistory()

  const initialValues = [[], []]
  const [categoriesData, setCategoriesData] = useState([])
  const [brandsData, setBrandsData] = useState([])
  const [fulfillmentsData, setFulfillmentsData] = useState([])
  const [providerData, setProviderData] = useState([])
  const [compediumData, setCompendiumData] = useState(initialValues)
  const [compendiaSearch, setCompendiaSearch] = useState(['', ''])

  const [
    isSavingModalOpen,
    { open: openSavingModal, close: closeSavingModal },
  ] = useDisclosure(false)

  const form = useForm({
    initialValues: KIT_FORM_INITIAL_VALUES,
    validate: KIT_FORM_VALIDATIONS,
  })

  const { data: compendiaPanelsData, loading: compendiaPanelsLoading } =
    useQuery(GET_COMPENDIUM_PANELS, {
      skip: !form.values.provider_id,
      variables: {
        providerId: form.values.provider_id,
        type: 'group',
        search: compendiaSearch[0],
      },
    })

  const { data: categoryList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: CATEGORIES_FILTER,
    },
  })

  const { data: brandsList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: BRANDS_FILTER,
    },
  })

  const { data: fullfillmentsList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: FULFILLMENTS_FILTER,
    },
  })

  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  useEffect(() => {
    if (!compendiaPanelsLoading) {
      const availablePanels = _.map(
        compendiaPanelsData?.listCompendiumPanels?.compendium_panels,
        (compendia) => ({
          value: compendia.code,
          label: `${compendia.code} - ${compendia.name}`,
        }),
      )
      const selectedPanelCodes = form.values.compendia_codes
      const selectedPanels = _.filter(availablePanels, (item) =>
        _.includes(selectedPanelCodes, item.value),
      )
      const availablePanelsFinal = availablePanels.filter(
        (panel) => !selectedPanelCodes.includes(panel.value),
      )

      const compendiaData = [availablePanelsFinal, selectedPanels]
      setCompendiumData(compendiaData)
    }
  }, [compendiaPanelsData])

  useEffect(() => {
    const categoryListSelectData = _.map(
      categoryList?.listConfigs?.configs,
      (config) => ({
        value: config.value,
        label: config.name,
      }),
    )
    setCategoriesData(categoryListSelectData)
  }, [categoryList])

  useEffect(() => {
    const brandsListSelectData = _.map(
      brandsList?.listConfigs?.configs,
      (config) => ({
        value: config.value,
        label: config.name,
      }),
    )
    setBrandsData(brandsListSelectData)
  }, [brandsList])

  useEffect(() => {
    const fullfillmentsListSelectData = _.map(
      fullfillmentsList?.listConfigs?.configs,
      (config) => ({
        value: config._id,
        label: config.name,
      }),
    )
    setFulfillmentsData(fullfillmentsListSelectData)
  }, [fullfillmentsList])

  useEffect(() => {
    const providerListSelectData = _.map(
      providerList?.listProviders?.providers,
      (provider) => ({
        value: provider._id,
        label: provider.name,
      }),
    )
    setProviderData(providerListSelectData)
  }, [providerList])

  useEffect(() => {
    const compendiaCodes = _.map(compediumData[1], 'value')
    form.setFieldValue('compendia_codes', compendiaCodes)
  }, [compediumData])

  useEffect(() => {
    if (location?.state?.laboratory) {
      form.setFieldValue('provider_id', location.state.laboratory._id)
    }
  }, [location])

  const [createKit, { loading: createKitLoading, error: createKitError }] =
    useMutation(CREATE_KIT)

  const handleBackToKitListClick = () => {
    push({
      pathname: KIT.base,
      state: { refetch: true },
    })
  }

  const handleCreateKitClick = async (values) => {
    openSavingModal()

    const valuesTidy = _.omit(values, [
      'meta1',
      'meta2',
      'meta3',
      'meta4',
      'meta5',
    ])

    await createKit({
      variables: {
        data: {
          ...valuesTidy,
          meta: [
            values.meta1,
            values.meta2,
            values.meta3,
            values.meta4,
            values.meta5,
          ],
        },
      },
    })
    setTimeout(() => {
      if (!createKitError) {
        handleBackToKitListClick()
      }
    }, 2000)
  }

  return (
    <MainContainer>
      <ScrollableContainer>
        <Helmet>
          <title>Create new Kit</title>
        </Helmet>

        <form
          onSubmit={form.onSubmit((values) => handleCreateKitClick(values))}
        >
          <ToolbarHeader
            title="Create a kit"
            backButtonAction={handleBackToKitListClick}
            backButtonText="Back to Kit List"
          />
          <FormSection>
            <Grid.Col md={6} lg={2}>
              <TextInput
                label="Name"
                placeholder="Kit Name"
                withAsterisk
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={4}>
              <TextInput
                label="Description"
                placeholder="Description"
                withAsterisk
                {...form.getInputProps('description')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <Select
                data={providerData}
                label="Laboratory"
                placeholder="Select a Laboratory"
                searchable
                withAsterisk
                {...form.getInputProps('provider_id')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <TextInput
                label="SKU Kit"
                placeholder="SKU Kit ID"
                withAsterisk
                {...form.getInputProps('sku_kit_id')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <TextInput
                label="SKU Lab"
                placeholder="SKU Lab ID"
                withAsterisk
                {...form.getInputProps('sku_lab_id')}
              />
            </Grid.Col>
          </FormSection>
          <FormSection>
            <Grid.Col md={6} lg={3}>
              <TextInput
                label="Short Name"
                placeholder="Short Name"
                {...form.getInputProps('short_name')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={3}>
              <TextInput
                label="Code"
                placeholder="Code"
                {...form.getInputProps('code')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <Select
                data={categoriesData}
                label="Category"
                placeholder="Select a Category"
                searchable
                disabled={categoriesData.length === 0}
                {...form.getInputProps('category')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <Select
                data={brandsData}
                label="Brand"
                placeholder="Select a Brand"
                searchable
                disabled={brandsData.length === 0}
                {...form.getInputProps('brand')}
              />
            </Grid.Col>
            <Grid.Col md={6} lg={2}>
              <Select
                data={fulfillmentsData}
                label="Fulfillment"
                placeholder="Select a Fulfillment"
                searchable
                withAsterisk
                disabled={fulfillmentsData.length === 0}
                {...form.getInputProps('fulfillment')}
              />
            </Grid.Col>
          </FormSection>
          <PanelSection>
            <TransferList
              value={compediumData}
              onChange={setCompendiumData}
              searchPlaceholder="Search..."
              nothingFound="Nothing here"
              listHeight={'450px'}
              searchValues={compendiaSearch}
              onSearch={setCompendiaSearch}
              titles={[
                'Available Panels from laboratory',
                'Selected Panels for this Kit',
              ]}
            />
            <ErrorMessage>{form.errors['compendia_codes']}</ErrorMessage>
          </PanelSection>
          {/* <MetaSection>
            <StyledTitle>Metadata</StyledTitle>
            <Grid>
              <Grid.Col lg={6}>
                <TextInput
                  label="Code"
                  placeholder="Code"
                  {...form.getInputProps('meta1.code')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  label="Value"
                  placeholder="Value"
                  {...form.getInputProps('meta1.value')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Code"
                  {...form.getInputProps('meta2.code')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Value"
                  {...form.getInputProps('meta2.value')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Code"
                  {...form.getInputProps('meta3.code')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Value"
                  {...form.getInputProps('meta3.value')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Code"
                  {...form.getInputProps('meta4.code')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Value"
                  {...form.getInputProps('meta4.value')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Code"
                  {...form.getInputProps('meta5.code')}
                />
              </Grid.Col>
              <Grid.Col lg={6}>
                <TextInput
                  placeholder="Value"
                  {...form.getInputProps('meta5.value')}
                />
              </Grid.Col>
            </Grid>
          </MetaSection> */}

          <StickyFooter>
            <EmptyContainer>s</EmptyContainer>
            <ButtonGroup>
              <Button
                radius="xl"
                size="md"
                uppercase
                variant="subtle"
                color="pink"
                onClick={handleBackToKitListClick}
              >
                Cancel
              </Button>
              <Button
                radius="xl"
                size="md"
                uppercase
                variant="filled"
                type="submit"
                disabled={!form.isValid()}
              >
                Create Kit
              </Button>
            </ButtonGroup>
          </StickyFooter>
        </form>

        <LoadingModal
          loadingMessage="We are saving your changes... please wait"
          successMessage="Your data has been saved"
          errorMessage="Something went wrong. Please try again later."
          isLoading={createKitLoading}
          opened={isSavingModalOpen}
          onClose={closeSavingModal}
          hasError={createKitError}
        />
      </ScrollableContainer>
    </MainContainer>
  )
}
